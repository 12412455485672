import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private router: Router,
        private cookieService: CookieService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
       // localStorage.setItem('accessToken', token);
        this.cookieService.set('accessToken', token);
    }

    get accessToken(): string
    {
        return this.cookieService.get('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append('Authorization','Basic ' + btoa(credentials.email + ':' + credentials.password));

         return this._httpClient.request<any>('post', `${environment.apiUrl}/login`,
        {
          headers: headers,
          body:credentials
        }
      ).pipe(
            switchMap((response: any) => {
                this.accessToken = response.info_user.token;
               // localStorage.setItem("accessToken",response.info_user.token)
               this.cookieService.set('accessToken', response.info_user.token);
                this._authenticated = true;
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        this._authenticated = false;
        this.cookieService.deleteAll();
       // location.reload();
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        if ( this._authenticated )
        {
            return of(true);
        }
       /*  if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }  */
        if ( !this.accessToken )
        {
            return of(false);
        }else {
          return of(true);
      }
   
    }

 
    public defaultHeaders = new HttpHeaders();
    public checkSession(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      const queryParameters = new HttpParams();
      let headers = this.defaultHeaders;
  
      return this._httpClient.request<any>('get', `${environment.apiUrl}/check_session`,
        {
          params: queryParameters,
          headers,
          observe,
          reportProgress
        }
      );
    }

    private sessionTimeout =  30 * 60 * 1000; 
    //private alertTimeout = 5 * 60 * 1000; 
  
  
    private sessionTimer: any;
    //private alertTimer: any;
    private showAlert = false;
  
  
    private initSessionTimeout() {
      this.sessionTimer = setTimeout(() => {
        this.showAlert = true;
        this.handleSessionTimeout();
      }, this.sessionTimeout);
    }
  
    resetSessionTimer() {
      clearTimeout(this.sessionTimer);
      this.initSessionTimeout();
    }
  
    private handleSessionTimeout() {
      if (this.showAlert) {
        this.router.navigate(['/sign-in']);
        setTimeout(() => {
          swal.fire({
            title: 'Session Expirée',
            text: 'Votre session a expiré. Veuillez vous reconnecter.',
            icon: 'info',
            showConfirmButton: true,
            confirmButtonText: 'Fermer',
            confirmButtonColor: '#3085d6',
          });
        }, 100); 
      }
    }
  
  
  /*   private alertWarning() {
      if (this.showAlert && this._authenticated) {
        const alert = swal
          .fire({
            title: '',
            text: 'La session expirera dans 5 minutes.',
            icon: 'warning',
            showConfirmButton: true,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Rester connecté',
            confirmButtonColor: '#e75e5e',
            cancelButtonText: 'Déconnecter',
            timerProgressBar: true,
          });
    
        setTimeout(() => {
          Swal.close(); 
        }, this.alertTimeout); 
    
        alert.then((result) => {
          if (result.dismiss === swal.DismissReason.cancel) {
            localStorage.clear();
            this.router.navigate(['/sign-in']);
          } else if (result.isConfirmed) {
            this.resetSessionTimer();
          }
        });
      }
    } */
  
}
