import { Component, HostListener } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor(private router: Router,
      private authService :AuthService,
      private cookieService: CookieService
     )
    {


      const accessToken=  this.cookieService.get('accessToken');
      if (!accessToken) {
        this.cookieService.deleteAll();
        this.router.navigate(['/sign-in']);
      }


 
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
      this.authService.resetSessionTimer();
    }
}
