import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-fichiers',
  templateUrl: './list-fichiers.component.html',
  styleUrls: ['./list-fichiers.component.scss']
})
export class ListFichiersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
